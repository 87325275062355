import React from "react";
import "../styles/Cardbestseller.css";
import checkIT from "../assets/checkD.png";
import website from '../assets/sitio-web.png'
import tienda from '../assets/venta.png'
import landing from '../assets/landingPage.png'
import pro from "../assets/essential-media.png"
import essential from "../assets/social-media-pro.png"
import starterMedia from "../assets/social-media.png"
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Loading from "./Loading";

export default function CardBestSeller() {

  const [dolar, setDolar] = useState()
  const [landingMonth, setLandingMonth] = useState()
  const [webSite, setWebSite] = useState()
  const [ecommerce, setEcommerce] = useState()

  const [landingState, setLandingState] = useState(true)
  const [productState, setProductState] = useState(true)
  const [ecommerceState, setEcommerceState] = useState(true)
  const [websiteState, setWebsiteState] = useState(true)



  useEffect(() => {

    const intervailId = setInterval(() => {
      axios.get('https://criptoya.com/api/dolar').then((res) => {
        if (res.data) {
          let dataReceive = res.data
          let dolarObject = dataReceive.oficial
          setDolar(dolarObject.price)
          let landingPerMonth = dolarObject.price * 100;
          let websitePerMonth = dolarObject.price * 170;
          let ecommercePerMonth = dolarObject.price * 280;
          setLandingMonth(landingPerMonth)
          setWebSite(websitePerMonth)
          setEcommerce(ecommercePerMonth)


        }

      }).catch((error) => console.log(error))
    }, 5000)
    return () => {
      clearInterval(intervailId)
    }
  }, [dolar])

  return (
    <div>

      <h2 className='title-partners mb-4'>Planes</h2>

      <div className="d-flex col justify-content-center align-items-center mb-5 gap-2 ">
        <button
          className={productState ? 'nav-button' : 'btn-price'}
          onClick={() => setProductState(!productState)}
        >Packs Web</button>
        <button
          className={productState ? 'btn-price' : 'nav-button'}
          onClick={() => setProductState(!productState)}
        >Packs Redes sociales</button>

      </div>

      {

        productState ? (
          <div id="plan" className="div-best-sellers">
            {/* Card #1 */}
            <div className="div-card-best shadow-lg">
              <div className="text-center">
                <img src={landing} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Landing Page</strong> </h2>
                <p>Transforma tus visitantes en clientes potenciales</p>
              </div>
              <div className="text-center pt-2">
                {
                  landingMonth ? (<h4 className="price-title">${landingState ? 18 : 99}</h4>) : <Loading />
                }

                <p className="price-text">Precio por {landingState ? 'mes' : 'único'} </p>
                <div className="div-btn-price">
                  <button className={landingState ? 'nav-button' : 'btn-price'} onClick={() => setLandingState(true)}  >Mes</button>
                  <button className={landingState ? 'btn-price' : 'nav-button'} onClick={() => setLandingState(false)} >Único</button>
                </div>

              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Dominio</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Hosting</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>5 Secciones</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Formulario de contacto</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Link a <strong>Whatsapp</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20Landing%20Page" target="_blank" >Contratar</a>

              </div>
            </div>
            {/* Card #2 */}
            <div className="div-card-best card-central shadow-lg">
              <div className="text-center">
                <img src={tienda} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Tienda OnLine</strong> </h2>
                <p>Crea un nuevo canal de ventas a través de internet</p>
              </div>
              <div className="text-center pt-2">
                {
                  ecommerce ? (<h4 className="price-title">${ecommerceState ? 49 : 260}</h4>) : <Loading />
                }


                <p className="price-text">Precio por {ecommerceState ? 'mes' : 'único'} </p>
                <div className="div-btn-price">
                  <button className={ecommerceState ? 'nav-button' : 'btn-price'} onClick={() => setEcommerceState(true)}  >Mes</button>
                  <button className={ecommerceState ? 'btn-price' : 'nav-button'} onClick={() => setEcommerceState(false)} >Único</button>
                </div>

              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Dominio</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Hosting</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Stock Administrable</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Correo Empresarial</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Base de datos</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Google Analytics</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Link a <strong>Whatsapp</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20Tienda%20Online" target="_blank" >Contratar</a>
              </div>
            </div>

            {/* Clard #3 */}
            <div className="div-card-best shadow-lg">
              <div className="text-center">
                <img src={website} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Sitio Web</strong></h2>
                <p>Posiciona, digitaliza y potencia tu marca en internet</p>
              </div>
              <div className="text-center pt-2">
                {
                  webSite ? (<h4 className="price-title">${websiteState ? 35 : 150}</h4>) : <Loading />
                }


                <p className="price-text">Precio por {websiteState ? 'mes' : 'único'} </p>
                <div className="div-btn-price">
                  <button className={websiteState ? 'nav-button' : 'btn-price'} onClick={() => setWebsiteState(true)}  >Mes</button>
                  <button className={websiteState ? 'btn-price' : 'nav-button'} onClick={() => setWebsiteState(false)} >Único</button>
                </div>

              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Dominio</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>Hosting</strong> web</p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>5 Paginas</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Correo empresarial</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Link a <strong>Whatsapp</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20sitio%20Web" target="_blank" >Contratar</a>
              </div>
            </div>

          </div>

        ) : (
          <div id="plan" className="div-best-sellers">
            {/* Card #1 */}
            <div className="div-card-best shadow-lg">
              <div className="text-center">
                <img src={pro} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Starter</strong> </h2>
                <p>¡Descubre tu potencial online con nuestro Plan Starter para redes sociales!</p>
              </div>
              <div className="text-center pt-2">
                {
                  landingMonth ? (<h4 className="price-title">$60</h4>) : <Loading />
                }

                <p className="price-text">Precio por mes </p>


              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>6 Posts</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>2 Reels</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>2 Stories</strong></p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Creación de LinkTree</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Copywriting para <strong>Posts</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20el%20programa%20para%20redes%20sociales%20Starter" target="_blank" >Contratar</a>

              </div>
            </div>
            {/* Card #2 */}
            <div className="div-card-best card-central shadow-lg">
              <div className="text-center">
                <img src={essential} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Pro</strong> </h2>
                <p>Alcanza nuevas alturas en línea con nuestro Plan Pro: ¡el secreto del éxito en redes sociales!.</p>
              </div>
              <div className="text-center p-2">
                {
                  ecommerce ? (<h4 className="price-title">$95</h4>) : <Loading />
                }


                <p className="price-text">Precio por mes </p>

              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>12 Posts</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>4 Reels</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>5 Stories</strong></p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Creación de LinkTree</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Copywriting para <strong>Posts</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Catálogo <strong>WhatsApp Negocios</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Tarjeta de presentación digital con <strong>QR</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20el%20programa%20para%20redes%20sociales%20Pro" target="_blank" >Contratar</a>
              </div>
            </div>

            {/* Clard #3 */}
            <div className="div-card-best shadow-lg">
              <div className="text-center">
                <img src={starterMedia} alt="tienda online" className="icon-card" />
                <h2 id="title-item-one"> <strong>Essential</strong></h2>
                <p>Optimiza tu presencia digital con nuestro Plan Essentials para redes sociales.</p>
              </div>
              <div className="text-center pt-2">
                {
                  webSite ? (<h4 className="price-title">$75</h4>) : <Loading />
                }


                <p className="price-text">Precio por {websiteState ? 'mes' : 'único'} </p>

              </div>
              <div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>8 Posts</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>4 Reels</strong></p>
                </div>
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p><strong>3 Stories</strong></p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Creación de LinkTree</p>
                </div>{" "}
                <div className="div-listed-include">
                  <img src={checkIT} alt="item" className="item-check" />
                  <p>Copywriting para <strong>Posts</strong></p>
                </div>
              </div>
              <div className="div-btn-comprar">
                <a className="btn btn-comprar" rel="noreferrer" href="https://wa.me/543518523090/?text=%20Hola,%20quisiera%20tener%20mayor%20información%20sobre%20el%20programa%20para%20redes%20sociales%20Essential" target="_blank" >Contratar</a>
              </div>
            </div>

          </div>
        )

      }


    </div>




  );
}
