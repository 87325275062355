
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Welcome from './pages/Welcome';



function App() {


  return (
    <Welcome/>
  )
}

export default App;
