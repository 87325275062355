import React from 'react'
import CardBestSeller from './CardBestSeller'

export default function BestSellers() {
  return (
    <div className='d-flex justify-content-center'>
        <CardBestSeller/>
    </div>
  )
}
